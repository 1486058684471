<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
      centered
      icons-and-text
    >
      <v-tabs-slider />

      <v-tab href="#tab-informations">
        {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.informations') }}
        <v-icon>mdi-information</v-icon>
      </v-tab>

      <v-tab href="#tab-traffic-light-durations">
        {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.trafficLightPlans') }}
        <v-icon>mdi-timeline-clock</v-icon>
      </v-tab>

      <v-tab href="#tab-counters">
        {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.counters') }}
        <v-icon>mdi-counter</v-icon>
      </v-tab>

      <v-tab href="#tab-traffic-lights">
        {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.trafficLights') }}
        <v-icon>mdi-traffic-light</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        value="tab-informations"
      >
        <v-row>
          <v-col class="d-flex">
            <v-spacer />
            <v-chip
              :color="model.location === null ? 'warning' : 'success'"
              class="ma-2"
              small
              @click="setCrossroadLocation"
            >
              <span v-if="model.location === null">
                {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.noPositionForCrossroad') }}
              </span>
              <span v-else>
                <v-icon
                  left
                  small
                >
                  mdi-target
                </v-icon>
                {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.changeCrossroadPosition') }}
              </span>
            </v-chip>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <o-text-field
              v-model="model.name"
              :rules="crossroadValidation.name"
              label="labels.crossroad.name"
              prepend-icon="mdi-rename-box"
              @change="onNameChanged"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-script-text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="model.trafficLightPlans.length">
                    {{ $t('labels.crossroad.trafficLightPlans') }} : {{ model.trafficLightPlans.length }}
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.noTrafficLightPlan') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-counter</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="model.counters.length">
                    {{ $t('labels.crossroad.counters') }} : {{ model.counters.length }}
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.noCounter') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-traffic-light</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="model.trafficLights.length">
                    {{ $t('labels.crossroad.trafficLights') }} : {{ model.trafficLights.length }}
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.noTrafficLight') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item
        value="tab-traffic-light-durations"
      >
        <v-row>
          <v-col v-if="model.trafficLightPlans.length">
            <o-form
              ref="form"
              @submit="saveTrafficLightDurations"
            >
              <v-row>
                <v-col>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td />
                          <td
                            v-for="(trafficLightPlan, i) in model.trafficLightPlans"
                            :key="`traffic-light-plan-${i}`"
                            class="text-center"
                          >
                            {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.trafficLightPlanNo', { number: trafficLightPlan.number}) }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.totalDuration') }}
                          </td>
                          <td
                            v-for="(trafficLightPlan, i) in model.trafficLightPlans"
                            :key="`traffic-light-plan-${i}`"
                          >
                            <o-text-field
                              v-model.number="trafficLightPlan.duration"
                              :rules="trafficLightPlanValidation.duration"
                              hide-details
                              dense
                              suffix="s"
                              class="input-text-right"
                            />
                          </td>
                        </tr>
                        <tr
                          v-for="(trafficLight, i) in model.trafficLights"
                          :key="`traffic-light-${i}`"
                        >
                          <td class="text-no-wrap">
                            {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.trafficLightNo', { number: trafficLight.number}) }}
                          </td>
                          <td
                            v-for="(trafficLightDuration, j) in trafficLightDurations[trafficLight.id]"
                            :key="`traffic-light-duration-${i}-${j}`"
                          >
                            <o-text-field
                              v-model.number="trafficLightDurations[trafficLight.id][j]"
                              :rules="trafficLightDurationValidation.duration"
                              hide-details
                              dense
                              suffix="s"
                              class="input-text-right"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <o-submit />
                </v-col>
              </v-row>
            </o-form>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item
        value="tab-counters"
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="counters.headers"
              :items="station.counters"
              disable-filtering
              disable-pagination
              fixed-header
              hide-default-footer
              dense
              sort-by="number"
              :items-per-page="-1"
              :no-data-text="$t('messages.views.admin.components.configuration.stations.window.crossroadWindow.noCounter')"
            >
              <template v-slot:item.checkbox="props">
                <v-checkbox
                  :value="model.counters.includes(props.item.id)"
                  @change="(value) => onCrossroadCounterChanged(props.item.id, value)"
                />
              </template>

              <template v-slot:item.type="props">
                {{ $t('constants.counter.type.' + props.item.type) }}
              </template>

              <template v-slot:item.sensors="props">
                <span class="text-truncate">
                  {{ station.sensors.find((sensor) => sensor.id === props.item.sensors[0]).name }}
                </span>
                <span v-if="props.item.sensors.length > 1">, ...</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item
        value="tab-traffic-lights"
      >
        <v-row>
          <v-col cols="12">
            <v-list v-if="model.trafficLights.length">
              <v-list-item
                v-for="(trafficLight, k) in model.trafficLights"
                :key="`trafficLight-${k}`"
                @click="() => $emit('traffic-light-click', trafficLight)"
              >
                <v-list-item-icon>
                  {{ trafficLight.number }}
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="d-flex">
                    <span>{{ trafficLight.name }}</span>
                    <v-spacer />
                    <v-chip
                      v-if="trafficLight.location === null"
                      color="warning"
                      class="mr-2"
                      small
                    >
                      {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.noPositionForTrafficLight') }}
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">
                    <span class="font-weight-bold">{{ $t('labels.trafficLight.type') + ' : ' + trafficLight.type }}</span>
                    -
                    <span>{{ getTrafficLightTypeTranslated(trafficLight.type) }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <p
              v-else
              class="ml-5 text--secondary font-italic"
            >
              {{ $t('messages.views.admin.components.configuration.stations.window.crossroadWindow.noTrafficLight') }}
            </p>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import { getTrafficLightTypeTranslationKey } from '@utils/trafficLight';

  export default {
    props: {
      value: {
        type: Object,
        required: true,
      },
      station: {
        type: Object,
        required: true,
      },
    },

    data: function () {
      return {
        tab: 0,
        crossroadValidation: require('@/validation/entities/crossroad.json'),
        trafficLightPlanValidation: require('@/validation/entities/trafficLightPlan.json'),
        trafficLightDurationValidation: require('@/validation/entities/trafficLightDuration.json'),
        trafficLightDurations: {},
        counters: {
          values: {},
          headers: [
            {
              value: 'checkbox',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$t('labels.counter.number'),
              value: 'number',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$t('labels.counter.type'),
              value: 'type',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$t('labels.counter.sensors'),
              value: 'sensors',
              sortable: false,
              align: 'center',
            },
          ],
        },
      };
    },

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
        },
      },
    },

    watch: {
      value: {
        immediate: true,
        handler (newValue) {
          // Update traffic light durations
          const trafficLightDurations = {};
          for (const trafficLight of newValue.trafficLights) {
            trafficLightDurations[trafficLight.id] = [];
            for (const trafficLightPlan of newValue.trafficLightPlans) {
              const trafficLightDuration = trafficLight.trafficLightDurations.find((trafficLightDuration) => trafficLightDuration.trafficLightPlan === trafficLightPlan.id);
              const duration = trafficLightDuration ? trafficLightDuration.duration : null;
              trafficLightDurations[trafficLight.id].push(duration);
            }
          }

          this.trafficLightDurations = trafficLightDurations;

          // Update counters
          for (const counter of this.station.counters) {
            this.counters.values[counter.id] = newValue.counters.includes(counter.id);
          }
        },
      },
    },

    methods: {
      getTrafficLightTypeTranslated (type) {
        return this.$t(getTrafficLightTypeTranslationKey(type));
      },

      onNameChanged () {
        this.update();
      },

      onCrossroadCounterChanged (counterId, value) {
        if (value) {
          this.model.counters.push(counterId);
        } else {
          this.model.counters.splice(this.model.counters.indexOf(counterId), 1);
        }

        this.update();
      },

      update () {
        this.$apollo.mutate({
          mutation: require('@gql/mutations/crossroad/updateCrossroad.gql'),
          client: 'stations',
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              id: this.model.id,
              name: this.model.name,
              counters: this.model.counters,
            },
          },
        }).catch((e) => {
          this.$flash(null, 'error');
        });
      },

      saveTrafficLightDurations () {
        const input = {
          crossroad: this.model.id,
          trafficLightPlans: [],
        };

        for (const index in this.model.trafficLightPlans) {
          const trafficLightPlan = this.model.trafficLightPlans[index];
          const trafficLightDurations = [];
          for (const trafficLightId of Object.keys(this.trafficLightDurations)) {
            if (this.trafficLightDurations[trafficLightId][index]) {
              trafficLightDurations.push({
                trafficLight: trafficLightId,
                duration: this.trafficLightDurations[trafficLightId][index],
              });
            }
          }

          input.trafficLightPlans.push({
            trafficLightPlan: trafficLightPlan.id,
            duration: trafficLightPlan.duration,
            trafficLightDurations: trafficLightDurations,
          });
        }

        this.loading = true;
        this.$apollo.mutate({
          mutation: require('@gql/mutations/trafficLightPlan/updateTrafficLightPlanDurations.gql'),
          client: 'stations',
          variables: {
            input: input,
          },
        }).then((result) => {
          this.$flash('messages.views.admin.components.configuration.stations.window.crossroadWindow.durationSavedWithSuccess', 'success');
          this.$refs.form.reset();
        }).catch((e) => {
          this.$flash(null, 'error');
        });
      },

      setCrossroadLocation () {
        this.$store.commit('map/changeMode', {
          mode: 'set_location',
        });
      },
    },
  };
</script>
